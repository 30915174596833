import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="diagonal"
export default class extends Controller {
  connect() {
    const diagonalBox = document.querySelector('#diagonal-box')
    const fold1 = document.querySelector('#fold-1')
    const szBody = document.querySelector('#sz-body')

    const observer = new ResizeObserver(entries => {
      if (entries[0].contentRect.width <= 480) {
        diagonalBox.style.top = `calc(${fold1.offsetHeight}px + 69vw)`
      } else if (entries[0].contentRect.width <= 1000) {
        diagonalBox.style.top = `calc(${fold1.offsetHeight}px + 45vw)`
      } else if (entries[0].contentRect.width <= 1100) {
        diagonalBox.style.top = `calc(${fold1.offsetHeight}px + 29.7rem)`
      } else if (entries[0].contentRect.width <= 1200) {
        diagonalBox.style.top = `calc(${fold1.offsetHeight}px + 31.5rem)`
      } else if (entries[0].contentRect.width <= 1440) {
        diagonalBox.style.top = `calc(${fold1.offsetHeight}px + 30.5rem)`
      } else {
        diagonalBox.style.top = `calc(${fold1.offsetHeight}px + 30.5em)`
      }

    })

    observer.observe(szBody)
  }
}
