import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="carousel"
export default class extends Controller {
  connect() {
    new bootstrap.Carousel(document.getElementById('sz-carousel'), {
      interval: 6000,
      pause: false,
      touch: true,
      keyboard: true
    });
  }
}
